<template>
  <div class="sign-card">
    <div class="card-notice">
<!--      <span>({{ month }}月)</span>-->
      <span>{{ rule.rule_str || "签到" }}</span>
      <span class="text-color" v-if="rule.money > 0"
        >{{ rule.money }}元优惠券</span
      >
    </div>
    <div class="date-section">
      <div class="date-list">
        <div class="list" v-for="(item, index) in dateList" :key="index">
          <div class="week">{{ item.week }}</div>
          <div
            class="date-box"
            :class="[
              { 'red-color': item.is_sign === 1 },
              { 'grey-color': item.is_sign === 0 },
            ]"
            v-if="item.coupon <= 0"
          >
            <div
              class="sign-tag"
              :class="[{ 'grey-color': item.is_sign === 0 }]"
            >
              签
            </div>
            <div
              class="sign-text"
              :class="[
                { 'white-color': item.is_sign === 0 || item.is_sign === 1 },
              ]"
            >
              {{ item.is_sign === 0 ? "未签" : item.date }}
            </div>
          </div>
          <div class="date-box coupon" v-else>
            <div class="coupon-bg" v-show="item.coupon > 0">
              {{ item.coupon }}张
            </div>
            <div class="text" v-show="item.coupon > 0">{{ item.coupon }}张</div>
          </div>
        </div>
      </div>
      <div class="progress">
        <div class="progress-line">
          <div
            class="progress-round"
            :class="[
              { 'red-color': item.is_sign === 1 },
              { 'grey-color': item.is_sign === 0 },
            ]"
            v-for="(item, index) in dateList"
            :key="index"
          >
            <van-icon name="success" color="#fff" v-if="item.is_sign === 1" />
          </div>
        </div>
      </div>
    </div>
    <div class="button-box">
      <div
        class="button"
        :class="{ 'bg-color': detail.is_sign === 0 }"
        @click="signIn"
      >
        {{ detail.is_sign === 1 ? "已签到，明天再来" : "立即签到" }}
      </div>
      <img
        src="@/assets/sign/finger@2x.png"
        class="hand"
        v-if="detail.is_sign === 0"
      />
    </div>
    <SignSuccess ref="refSuccess"></SignSuccess>
    <SignDay ref="refDay"></SignDay>
  </div>
</template>

<script>
import SignSuccess from "../../components/sign-success/sign-success";
import SignDay from "../../components/sign-day/sign-day";
import { getSignRecord, getSignDetail, setSign } from "@/api/sign.js";
import { Toast } from "vant";
export default {
  components: {
    SignSuccess,
    SignDay,
  },
  props: ["month", "rule", "routeDate"],
  data() {
    return {
      dateList: [1, 2, 3, 4, 5, 6, 7],
      detail: {
        is_sign: 0,
      },
    };
  },
  mounted() {
    // this.getSignDetail();
  },
  methods: {
    signIn() {
      if (this.detail.is_sign === 0) {
        // this.setSign();
      }
    },
    async getSignDetail() {
      const res = await getSignDetail({ date: this.routeDate });
      if (res.code === 1) {
        this.detail = res.data.info;
        if (res.data.info.is_sign === 0) {
          this.setSign();
        } else {
          this.getSignRecord();
        }
      }
    },
    async setSign() {
      const res = await setSign({ date: this.routeDate });
      if (res.code === 1) {
        this.detail.is_sign = 1;
        this.getSignDetail();
        console.log(res.data);
        if (res.data.coupon.num >= 1) {
          this.$refs.refSuccess.openPopup(res.data);
        } else {
          this.$refs.refDay.openPopup(res.data);
        }
      } else {
        Toast.fail(res.msg);
        this.getSignRecord();
      }
    },
    async getSignRecord() {
      const res = await getSignRecord({ date: this.routeDate });
      if (res.code === 1) {
        this.dateList = res.data;
        this.$emit("getSignDetail", this.detail);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.sign-card {
  background: rgb(255, 255, 255);
  border-radius: 13px;
  padding: 16px;
  margin: 0 12px;
}
.date-section {
  margin-top: 21px;
}
.card-notice {
  font-size: 18px;
}
.date-list {
  display: flex;
  justify-content: space-between;
  .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    .week {
      margin-bottom: 8px;
      color: #999999;
      font-size: 13px;
    }
    .date-box {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      width: 47px;
      height: 67px;
      background: rgb(255, 226, 227);
      border-radius: 5px;
    }
    .sign-tag {
      width: 23px;
      height: 23px;
      color: #fff;
      font-size: 12px;
      border: 1px solid #ffffff;
      background: linear-gradient(180deg, #ee939d 0%, #f4525f 100%);
      border-radius: 50%;
      text-align: center;
      line-height: 23px;
    }
    .sign-text {
      margin-top: 5px;
      font-size: 13px;
    }
  }
}
.coupon {
  .coupon-bg {
    width: 47px;
    height: 34px;
    padding-left: 10px;
    background: url("../../../../assets/sign/coupon-icon@2x.png") no-repeat
      center/100% 100%;
    font-size: 12px;
    color: #ed301d;
    line-height: 37px;
    box-sizing: border-box;
  }
  .text {
    font-size: 13px;
  }
}
.progress {
  margin-top: 16px;
  padding: 0 16px;
  .progress-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 2px;
    background: #ffe2e3;
    .progress-round {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      background: rgb(255, 226, 227);
      border-radius: 50%;
    }
  }
}
.button-box {
  position: relative;
  margin-top: 25px;
  padding: 0 10px;
  .button {
    height: 57px;
    font-size: 21px;
    color: #fff;
    background: #cdcdcf;
    border-radius: 57px;
    text-align: center;
    line-height: 57px;
  }
  .bg-color {
    background: linear-gradient(90deg, #ff575b 0%, #ff3126 100%);
  }
  .hand {
    position: absolute;
    top: 28px;
    right: 74px;
    width: 32px;
    height: 42px;
  }
}
.red-color {
  background: linear-gradient(180deg, #ff353c 0%, #ff6f72 100%) !important;
}
.grey-color {
  background: #e5e5e5 !important;
}
.white-color {
  color: #fff !important;
}
</style>
