<template>
  <div class="sign-page">
    <NavBar class="navbar">
      <div slot="title">签到有礼</div>
    </NavBar>
    <Notice
      :textList="couponList"
      v-if="couponList.length > 0"
      @checkCoupon="checkCoupon"
    ></Notice>
    <div class="main">
      <div class="section-button" @click="ruleRouter">规则</div>
      <div class="sign-img-box">
        <div class="sign-img">
          <div class="day-title">{{ month || 0 }}月已累计签到</div>
          <div class="day-num">
            <span class="num">{{ signDetail.sign_count || 0 }}</span>
            <span class="day">天</span>
          </div>
          <div class="sign-status">
            {{ signDetail.is_sign === 1 ? "今日已签到" : "今日未签到" }}
          </div>
        </div>
      </div>
      <SignCard
        :rule="couponInfo"
        :routeDate="routeDate"
        :month="month"
        @getSignDetail="getSignDetail"
      ></SignCard>
    </div>
    <div>
      <div class="recommend">—为你推荐—</div>
      <GoodsShowLabel :tagId="0" :goodsType="3" :sendType="3"></GoodsShowLabel>
    </div>
    <MoreCoupon ref="refCoupon"></MoreCoupon>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import Notice from ".././moudles/notice/notice";
import SignCard from ".././moudles/sign-card/sign-card";
import GoodsShowLabel from "@/components/goods-show/goods-show-label";
import MoreCoupon from "../components/more-coupon/more-coupon";
import { getMoreCoupon } from "@/api/sign.js";
import { Toast } from "vant";

export default {
  name: "sign-in",
  components: {
    NavBar,
    Notice,
    SignCard,
    GoodsShowLabel,
    MoreCoupon,
  },
  data() {
    return {
      signDetail: {},
      month: "",
      routeDate: "",
      couponList: [],
      couponInfo: {},
    };
  },
  created() {
    this.routeDate = this.$route.query.date;
  },
  mounted() {
    // this.getMoreCoupon();
    this.month = new Date().getMonth() + 1;
  },
  methods: {
    getSignDetail(data) {
      this.signDetail = data;
      // this.getMoreCoupon();
    },
    checkCoupon() {
      if (this.couponList.length > 0) {
        this.$refs.refCoupon.openPopup(this.couponList);
      } else {
        Toast.fail("没有更多优惠券");
      }
    },
    async getMoreCoupon() {
      const res = await getMoreCoupon({ date: this.routeDate });
      if (res.code === 1) {
        if (res.data.length > 0) {
          this.couponList = res.data;
          this.couponInfo = res.data[0];
          this.couponList = this.couponList.map((item) => {
            if (item.rule === 1) {
              item.dayText = `累计签到${item.day}天可领`;
            } else if (item.rule === 2) {
              item.dayText = `连续签到${item.day}天可领`;
            } else if (item.rule === 3) {
              item.dayText = `${item.date_time
                .formatTime("{1}月{2}日")
                .split(" ")}签到可领`;
            } else if (item.rule === 4) {
              let weeks = [
                "周一",
                "周二",
                "周三",
                "周四",
                "周五",
                "周六",
                "周日",
              ];
              let week = weeks[item.week - 1];
              item.dayText = `${week}签到可领`;
            }
            item.dayText+=(item.money+"元优惠券");
            console.log( item.dayText)
            return item;
          });
        } else {
          this.couponInfo = {};
          this.couponList = [];
        }
      }
    },
    ruleRouter() {
      this.$router.push({ name: "rule" });
    },
  },
};
</script>

<style lang="less" scoped>
.sign-page {
  width: 100vw;
  min-height: 100vh;
  padding-top: 51px;
  background: url("../../../assets/sign/qiandao-bg.png") no-repeat top/100%;
  box-sizing: border-box;
}
.navbar {
  background-color: #fff;
}
.main {
  position: relative;
  .sign-img-box {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    .sign-img {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 208px;
      height: 208px;
      background: url("../../../assets/sign/icon@2x.png") no-repeat center/100%
        100%;
    }
    .day-title {
      position: absolute;
      top: 56px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 13px;
      color: #999999;
    }
    .sign-status {
      position: absolute;
      bottom: 33px;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      font-size: 21px;
      color: #ffffff;
      text-align: center;
    }
  }
  .section-button {
    position: absolute;
    right: 0;
    top: 19px;
    width: 63px;
    height: 30px;
    font-size: 18px;
    color: #fff;
    background: rgba(4, 4, 4, 0.4);
    border-radius: 15px 0px 0px 15px;
    text-align: center;
    line-height: 30px;
  }
}
.day-num {
  .day {
    font-size: 13px;
  }
  .num {
    font-size: 48px;
  }
}
.recommend {
  padding: 18px;
  font-size: 18px;
  text-align: center;
}
</style>
