<template>
  <div>
    <div class="notice">
      <div class="notice-left notice-common">
        <span class="left-text">提醒</span>
        <van-icon name="volume-o" class="icon" />
      </div>
      <van-swipe
        class="notice-content"
        :show-indicators="false"
        :autoplay="2000"
        :vertical="true"
      >
        <van-swipe-item
          class="swipe-item"
          v-for="(item, index) in textList"
          :key="index"
        >
          {{ item.dayText }}
        </van-swipe-item>
      </van-swipe>
      <div class="notice-right notice-common" @click="checkCoupon">
        <span class="right-text">查看更多</span>
        <van-icon name="arrow" class="icon" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["textList"],
  data() {
    return {};
  },
  methods: {
    checkCoupon() {
      this.$emit("checkCoupon");
    },
  },
};
</script>

<style lang="less" scoped>
.notice {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  padding: 0 12px;
  font-size: 13px;
  color: #ed301d;
  background-color: #ffe5ca;
  overflow: hidden;
}
.notice-content {
  flex: 1;
  height: 100% !important;
  margin: 0 10px;
}
.swipe-item {
  display: flex;
  align-items: center;
}
.icon {
  padding-top: 2px;
}
.notice-common {
  display: flex;
  align-items: center;
  height: 100%;
  .left-text {
    margin-right: 4px;
  }
  .right-text {
    margin-left: 4px;
  }
}
</style>
