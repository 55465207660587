<template>
  <van-popup v-model="show">
    <div class="popup-block">
      <div class="block-bg">
        <div class="popup-title">更多优惠券</div>
        <ul class="list-block">
          <li class="list" v-for="(item, index) in couponList" :key="index">
            <div class="list-left">
              <Price
                :price="item.money"
                decimalStyle="13"
                integerStyle="22"
                spanColor="#ed301d"
              ></Price>
              <div class="condition">
                {{
                  item.full_type === 1 ? `满${item.full_money}元可用` : "无门槛"
                }}
              </div>
            </div>
            <div class="list-right">
              <div class="coupon-name">
                {{ item.name }}
              </div>
              <!-- <div class="coupon-date">{{ item.time_str }}</div> -->
              <div class="label-box">
                <div
                  class="label-text"
                  :class="{ bg: item.rule === 3 || item.rule === 4 }"
                  v-if="item.rule !== 1"
                >
                  {{ item.dayText }}
                </div>
                <div class="label-bg" v-else>{{ item.dayText }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <van-icon name="close" class="close-icon" @click="closePopup" />
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      couponList: [],
    };
  },
  methods: {
    openPopup(couponList) {
      this.couponList = couponList;
      this.show = true;
    },
    closePopup() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background: transparent;
}
.block-bg {
  position: relative;
  width: 348px;
  height: 460px;
  padding: 72px 14px 14px 14px;
  background: url("../../../../assets/sign/more-bg@2x.png") no-repeat
    center/100% 100%;
  border-radius: 10px;
  box-sizing: border-box;
  .popup-title {
    position: absolute;
    top: 23px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 22px;
    color: #fff;
    text-align: center;
  }
}
.list-block {
  height: 100%;
  overflow: auto;
}
.list {
  display: flex;
  width: 100%;
  height: 91px;
  background: url("../../../../assets/sign/list-bg.png") no-repeat center/100%
    100%;
  margin-bottom: 9px;
  &:last-child {
    margin: 0;
  }
}
.list-left {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90px;
  padding-left: 3px;
  box-sizing: border-box;
  .condition {
    font-size: 11px;
    color: #ed301d;
    text-align: center;
  }
}
.list-right {
  flex: 1;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  .coupon-name {
    font-size: 17px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    word-break: break-all;
  }
  .coupon-date {
    margin-top: 7px;
    color: #999999;
    font-size: 11px;
  }
  .label-box {
    position: absolute;
    right: 0;
    bottom: 10px;
    color: #fff;
    .label-text {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      padding: 0 10px;
      font-size: 11px;
      background: linear-gradient(90deg, #f96e11 0%, #fd826a 100%);
      border-radius: 3px 0px 0px 3px;
    }
    .bg {
      background: rgb(252, 206, 89);
      border-radius: 11px 0px 0px 11px;
    }
    .label-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 18px;
      padding: 0 10px;
      font-size: 11px;
      background: url("../../../../assets/sign/label-bg@2x.png") no-repeat
        center/100% 100%;
    }
  }
}
.close-icon {
  width: 100%;
  margin-top: 22px;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
</style>