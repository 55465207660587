import { render, staticRenderFns } from "./sign-success.vue?vue&type=template&id=9b3ba052&scoped=true"
import script from "./sign-success.vue?vue&type=script&lang=js"
export * from "./sign-success.vue?vue&type=script&lang=js"
import style0 from "./sign-success.vue?vue&type=style&index=0&id=9b3ba052&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9b3ba052",
  null
  
)

export default component.exports