<template>
  <van-popup v-model="show">
    <div class="popup-block">
      <div class="bg-block">
        <img src="@/assets/sign/font@2x.png" class="font-img" />
        <div class="text">
          {{
            successInfo.coupon.num > 1 ? "2张优惠券领取成功" : "优惠券领取成功"
          }}
        </div>
        <div class="bg-coupon">
          <div class="coupon-content" v-if="successInfo.coupon.num > 1">
            <div class="content-up">
              <span class="number">{{ successInfo.coupon.num || 0 }}</span>
              <span class="unit">张</span>
            </div>
            <div class="content-bottom">优惠券</div>
          </div>
          <div class="coupon-content" v-else>
            <div class="content-up">
              <span class="number money-color">{{
                successInfo.coupon.money
              }}</span>
              <span class="unit money-color">元</span>
            </div>
            <div class="content-bottom">
              {{
                successInfo.coupon.full_type === 1
                  ? `满${successInfo.coupon.full_money}元可用`
                  : "无门槛"
              }}
            </div>
          </div>
        </div>
        <div class="text">天天签到领更多优惠券</div>
        <div class="use-button" @click="goToRouter">
          {{ successInfo.coupon.num > 1 ? "查看优惠券" : "去使用" }}
        </div>
      </div>
      <van-icon name="close" class="close-popup" @click="closePopup" />
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      successInfo: {
        coupon: {
          num: 0,
        },
      },
    };
  },
  methods: {
    goToRouter() {
      if (this.successInfo.coupon.num > 1) {
        this.$router.push({
          name: "my-discounts",
        });
      } else {
        this.$router.push({
          name: "discounts",
          query: {
            couponId: this.successInfo.coupon.coupon_id,
            detailId: this.successInfo.coupon.id,
          },
        });
      }
    },
    openPopup(data) {
      this.successInfo = data;
      this.show = true;
    },
    closePopup() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background: transparent;
}
.bg-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 440px;
  padding-top: 45px;
  padding-bottom: 28px;
  background: url("../../../../assets/sign/popup-bg@2x.png") no-repeat center /
    100% 100%;
  box-sizing: border-box;
  .font-img {
    width: 195px;
    height: 46px;
  }
  .text {
    font-size: 18px;
    color: #905315;
  }
  .bg-coupon {
    width: 157px;
    height: 163px;
    margin-top: 28px;
    margin-bottom: 11px;
    background: url("../../../../assets/sign/bg-hongbao.png") no-repeat center /
      100% 100%;
  }
  .use-button {
    width: 195px;
    height: 49px;
    margin-top: 22px;
    font-size: 19px;
    color: #fff;
    background: linear-gradient(98deg, #ff8e01 0%, #db0512 100%);
    box-shadow: 0px 1px 2px rgba(235, 71, 24, 0.29);
    border-radius: 49px;
    text-align: center;
    line-height: 49px;
  }
}
.coupon-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 27px 0;
  box-sizing: border-box;
  .content-up {
    .number {
      font-size: 38px;
    }
    .unit {
      font-size: 14px;
    }
    .money-color {
      color: #e62a2c;
    }
  }
  .content-bottom {
    font-size: 18px;
    color: #fff;
  }
}
.close-popup {
  width: 100%;
  margin-top: 22px;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
</style>