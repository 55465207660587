<template>
  <van-popup v-model="show">
    <div class="popup-block">
      <div class="bg-block">
        <img src="@/assets/sign/font@2x.png" class="font-img" />
        <div class="text">已连续签到{{ seriesCount }}天</div>
        <div class="bg-coupon">
          <div>
            <span class="number">{{ seriesCount || 0 }}</span>
            <span class="unit">天</span>
          </div>
        </div>
        <div class="text">天天签到领更多优惠券</div>
        <div class="use-button" @click="closePopup">知道了</div>
      </div>
      <van-icon name="close" class="close-popup" @click="closePopup" />
    </div>
  </van-popup>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      seriesCount: 0,
    };
  },
  methods: {
    openPopup(data) {
      this.seriesCount = data.series_count;
      this.show = true;
    },
    closePopup() {
      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.van-popup {
  background: transparent;
}
.bg-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 440px;
  padding-top: 45px;
  padding-bottom: 28px;
  background: url("../../../../assets/sign/popup-bg@2x.png") no-repeat center /
    100% 100%;
  box-sizing: border-box;
  .font-img {
    width: 195px;
    height: 46px;
  }
  .text {
    font-size: 18px;
    color: #905315;
  }
  .bg-coupon {
    display: flex;
    justify-content: center;
    width: 135px;
    height: 172px;
    margin: 16px 0;
    padding-top: 78px;
    background: url("../../../../assets/sign/sign-day@2x.png") no-repeat center /
      100% 100%;
    box-sizing: border-box;
    .number {
      font-size: 45px;
    }
    .unit {
      font-size: 18px;
    }
  }
  .use-button {
    width: 195px;
    height: 49px;
    margin-top: 22px;
    font-size: 19px;
    color: #fff;
    background: linear-gradient(98deg, #ff8e01 0%, #db0512 100%);
    box-shadow: 0px 1px 2px rgba(235, 71, 24, 0.29);
    border-radius: 49px;
    text-align: center;
    line-height: 49px;
  }
}
.close-popup {
  width: 100%;
  margin-top: 22px;
  color: #fff;
  font-size: 40px;
  text-align: center;
}
</style>